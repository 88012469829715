import React from 'react';
import PropTypes from 'prop-types';
import SCHRIFTSATZ_STATUS from '../../constants/schriftsatz.status';
import { ReactComponent as InfoSVG } from '../../assets/icons/mz-information-white.svg';
import { createTooltipBySchriftsatzStatus } from '../../utils/schriftsatz.utils';
import WORKFLOW_STATUS from '../../constants/schriftsatz.workflowStatus.json';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function SchriftsatzBadge(props) {
  const {
    inList,
    workflowStatus,
    schriftsatzStatus,
    existNotTransferredJustizbox,
  } = props;

  let badgeColor = (schriftsatzStatus && SCHRIFTSATZ_STATUS[schriftsatzStatus] && SCHRIFTSATZ_STATUS[schriftsatzStatus].color) || '';
  let badgeLabel = (schriftsatzStatus && SCHRIFTSATZ_STATUS[schriftsatzStatus] && SCHRIFTSATZ_STATUS[schriftsatzStatus].label) || '';

  const showInfoIcon = schriftsatzStatus && (schriftsatzStatus === SCHRIFTSATZ_STATUS.UEBERTRAGUNGS_FEHLER.value || schriftsatzStatus === SCHRIFTSATZ_STATUS.ZURUECKGEWIESEN.value);
  const tooltipText = showInfoIcon && createTooltipBySchriftsatzStatus(schriftsatzStatus);

  if (schriftsatzStatus === SCHRIFTSATZ_STATUS.IN_ARBEIT.value && workflowStatus === WORKFLOW_STATUS.VISUALISIERUNG_OK) {
    badgeColor = (schriftsatzStatus && SCHRIFTSATZ_STATUS.VISUALISIERT.color) || '';
    badgeLabel = (schriftsatzStatus && SCHRIFTSATZ_STATUS.VISUALISIERT.label) || '';
  }

  if (workflowStatus === WORKFLOW_STATUS.TST_FEHLER) {
    badgeColor = (schriftsatzStatus && SCHRIFTSATZ_STATUS.TST_FEHLER.color) || '';
    badgeLabel = (schriftsatzStatus && `${SCHRIFTSATZ_STATUS[schriftsatzStatus].label}${SCHRIFTSATZ_STATUS.TST_FEHLER.label}`) || '';
    badgeColor = `${badgeColor} with-225px`;
  }

  badgeColor = `${badgeColor}${existNotTransferredJustizbox ? ' with-230px' : ''}`;
  badgeLabel = `${badgeLabel}${existNotTransferredJustizbox ? ' (Dok. unvollständig)' : ''}`;

  return (
    <div className={`d-block ${inList ? 'ml-3' : 'badge-in-list'}`}>
      <div className={`d-flex align-items-center justify-content-center error-status-tooltip-container badge ${badgeColor}`}>
        <span>
          {badgeLabel}
        </span>
        {showInfoIcon
        && (
          <>
            <InfoSVG className="icon-sm info-icon" title={badgeLabel} />
            <span className="error-status-tooltip">
              {tooltipText}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

SchriftsatzBadge.defaultProps = {
  inList: false,
  workflowStatus: null,
  schriftsatzStatus: 'IN_ARBEIT',
  existNotTransferredJustizbox: false,
};

SchriftsatzBadge.propTypes = {
  inList: PropTypes.bool,
  workflowStatus: PropTypes.string,
  schriftsatzStatus: PropTypes.string,
  existNotTransferredJustizbox: PropTypes.string,
};

export default SchriftsatzBadge;
