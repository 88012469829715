export const ERVBOX_LEGAL_DOK_FORMAT = [
  'application/vnd.yamaha.smaf-audio',
  'application/x-dvi',
  'application/x-futuresplash',
  'application/ogg',
  'application/mp21',
  'application/mp4',
  'application/x-shockwave-flash',
  'audio/adpcm',
  'audio/basic',
  'audio/midi',
  'audio/mp4',
  'audio/mpeg',
  'audio/ogg',
  'audio/s3m',
  'audio/silk',
  'audio/vnd.dece.audio',
  'audio/vnd.digital-winds',
  'audio/vnd.dra',
  'audio/vnd.dts',
  'audio/vnd.dts.hd',
  'audio/vnd.lucent.voice',
  'audio/vnd.ms-playready.media.pya',
  'audio/vnd.nuera.ecelp4800',
  'audio/vnd.nuera.ecelp7470',
  'audio/vnd.nuera.ecelp9600',
  'audio/vnd.rip',
  'audio/webm',
  'audio/x-aac',
  'audio/x-aiff',
  'audio/x-caf',
  'audio/x-flac',
  'audio/x-matroska',
  'audio/x-mpegurl',
  'audio/x-ms-wax',
  'audio/x-ms-wma',
  'audio/x-pn-realaudio',
  'audio/x-pn-realaudio-plugin',
  'audio/x-wav',
  'audio/xm',
  'video/3gpp',
  'video/3gpp2',
  'video/h261',
  'video/h263',
  'video/h264',
  'video/jpeg',
  'video/jpm',
  'video/mj2',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/quicktime',
  'video/vnd.dece.hd',
  'video/vnd.dece.mobile',
  'video/vnd.dece.pd',
  'video/vnd.dece.sd',
  'video/vnd.dece.video',
  'video/vnd.dvb.file',
  'video/vnd.fvt',
  'video/vnd.mpegurl',
  'video/vnd.ms-playready.media.pyv',
  'video/vnd.uvvu.mp4',
  'video/vnd.vivo',
  'video/webm',
  'video/x-f4v',
  'video/x-fli',
  'video/x-flv',
  'video/x-m4v',
  'video/x-matroska',
  'video/x-mng',
  'video/x-ms-asf',
  'video/x-ms-vob',
  'video/x-ms-wm',
  'video/x-ms-wmv',
  'video/x-ms-wmx',
  'video/x-ms-wvx',
  'video/x-msvideo',
  'video/x-sgi-movie',
  'video/x-smv',
  'application/pdf',
  'application/xml',
  'text/plain',
  'application/zip',
  'application/xhtml',
];

export const PDF_FORMAT = [
  'application/pdf',
];

export const ONLY_FB_ERVBOX_LEGAL_DOK_FORMAT = [
  'application/zip',
  'application/xhtml',
  'application/x-zip-compressed',
];
